import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
// import { useAppContext } from '../assets/scripts/contextLib';
import { LoaderButton } from './LoaderButton';
import { useRouter, useFormFields } from '../assets/scripts/hooksLib';
import { adjustHeader } from '../assets/scripts/utilsLib';
import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/login.scss';

/**
 * User login form
 * @returns {HTMLElement} html for login block
 */
export default function Login() {
    const router = useRouter();
    // const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        name: '',
        password: ''
    });
    const [signUp, setSignUp] = useState('/signup.html');
    const [newPassword, setNewPassword] = useFormFields({
        password: '',
        confirm: ''
    });
    const [changePasswordForm, setChangePasswordForm] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState();

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // adjusts the page header
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            adjustHeader();
        }

        if (router.query && router.query.redirect) {
            setSignUp(`/signup.html?redirect=${router.query.redirect}`);
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setSignUp]);

    /**
     * Checks and confirms the form fields are complete
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validateForm = () => {
        return fields.email.length > 0 && fields.password.length > 0;
    };

    /**
     * Authenticates the user credentials and redirects if valid
     * @param {MouseEvent|KeyEvent} event mouse click or Enter key pressed
     * @returns {void}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.signIn(fields.email, fields.password)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setAuthenticatedUser(user);
                        setIsLoading(false);
                        setChangePasswordForm(true);
                    } else {
                        // userHasAuthenticated(true);
                        setIsLoading(false);

                        if (router.query.redirect) {
                            // router.history(router.query.redirect);
                            location.href = router.query.redirect;
                        } else {
                            // router.history('/apps');
                            location.href = '/app';
                        }
                    }
                })
                .catch(err => {
                    // console.log(err);
                    if (err.code === 'UserNotConfirmedException') {
                        window.alert('Please confirm your user account by checking your email for the Stitchz.net verification code from no-reply@stitchz.net.');
                        // router.history('/signup?confirm=true');
                        location.href = '/signup.html?confirm=true';
                    } else {
                        onError(err);
                        setIsLoading(false);
                    }
                });
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    /**
     * Checks and confirms the passwords match
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validatePasswordsMatch = () => {
        return fields.name.length > 0
            && newPassword.password.length > 0
            && newPassword.confirm.length > 0
            && newPassword.password === newPassword.confirm;
    };

    /**
     * First time signing in, so let's update the user's password, add a username and redirect if valid
     * @param {MouseEvent|KeyEvent} event mouse click or Enter key pressed
     * @returns {void}
     */
    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.completeNewPassword(
                authenticatedUser,         // the Cognito User Object
                newPassword.password,      // the new password
                {
                    name: fields.name || fields.email
                }
            ).then(() => {
                setIsLoading(false);
                if (router.query.redirect && !['%2Flogin', '%2Fsignup'].includes(router.query.redirect)) {
                    // router.history(router.query.redirect);
                    location.href = router.query.redirect;
                } else {
                    // router.history('/apps');
                    location.href = '/apps';
                }
            })
                .catch(err => {
                    console.log(err);
                    // router.history('/signup');
                    location.href = '/signup.html';
                });
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    /**
     * Builds the change password form with optional username field
     * @returns {HTMLElement} html
     */
    const renderChangePasswordForm = () => {
        return (
            <Row className='Login'>
                <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <h4 className='center'>Change Your Temporary Password</h4>
                    <form onSubmit={handleChangePasswordSubmit}>
                        <Form.Group controlId='name'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                autoFocus
                                placeholder='Add Your Username'
                                type='text'
                                value={fields.name}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                autoFocus
                                type='password'
                                value={newPassword.password}
                                onChange={setNewPassword}
                            />
                        </Form.Group>
                        <Form.Group controlId='confirm'>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type='password'
                                onChange={setNewPassword}
                                value={newPassword.confirm}
                            />
                        </Form.Group>
                        <LoaderButton
                            type='submit'
                            isLoading={isLoading}
                            disabled={!validatePasswordsMatch()}
                        >
                            Save
                        </LoaderButton>
                    </form>
                </Col>
            </Row>
        );
    };

    return (
        changePasswordForm ?
            renderChangePasswordForm() :
            <Row className='Login'>
                <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <form onSubmit={handleSubmit}>
                        <Form.Group controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                type='email'
                                value={fields.email}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                value={fields.password}
                                onChange={handleFieldChange}
                                type='password'
                            />
                        </Form.Group>
                        <Link to='/login/reset.html' rel='nofollow'>Forgot password?</Link>
                        <Link to={signUp}>Sign Up</Link>
                        <LoaderButton
                            block='true'
                            type='submit'
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Login
                        </LoaderButton>
                    </form>
                </Col>
            </Row>
    );
}
